var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"}},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"numero","sortDesc":false,"search":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"numero",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(row.numero))]),_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(row.data_criacao,_vm.getTimeZone)))]),_c('div',[_vm._v(_vm._s(row.cliente_nome))])]}},{key:"pendente_pagamento",fn:function(ref){
var col = ref.col;
return [(col)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"error"}},[_vm._v(" Pendente ")]):_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"success"}},[_vm._v(" Em dia ")])]}},{key:"ativo",fn:function(ref){
var col = ref.col;
return [(col)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"success"}},[_vm._v(" Ativo ")]):_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"error"}},[_vm._v(" Inativo ")])]}},{key:"idassinatura",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":row.ativo ? _vm.tableOptions : _vm.tableOptionsAssInativa,"loading":_vm.loadingRow[row.idassinatura]},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }