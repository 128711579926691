<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="numero"
        :sortDesc="false"
        ref="datatable"
        search
        @click="onClick"
      >
        <template v-slot:numero="{ row }">
          <div class="font-weight-bold">{{ row.numero }}</div>
          <div>{{ row.data_criacao | formatDateTime(getTimeZone) }}</div>
          <div>{{ row.cliente_nome }}</div>
        </template>
        <template v-slot:pendente_pagamento="{ col }">
          <v-chip v-if="col" color="error" class="font-weight-bold">
            Pendente
          </v-chip>
          <v-chip v-else color="success" class="font-weight-bold">
            Em dia
          </v-chip>
        </template>
        <template v-slot:ativo="{ col }">
          <v-chip v-if="col" color="success" class="font-weight-bold">
            Ativo
          </v-chip>
          <v-chip v-else color="error" class="font-weight-bold">
            Inativo
          </v-chip>
        </template>
        <template v-slot:idassinatura="{ row }">
          <FormButtonMenu
            icon
            :options="row.ativo ? tableOptions : tableOptionsAssInativa"
            :loading="loadingRow[row.idassinatura]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { ASSINATURA_REMOVE } from '@/store/actions/assinatura'
import * as _ from 'lodash'

export default {
  name: 'AcompanhamentoTable',
  components: {
    DataTable,
    Panel,
    FormButtonMenu,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'Assinatura', value: 'numero' },
      { text: 'Plano', value: 'nome_recorrencia' },
      {
        text: 'Próxima entrega',
        value: 'proxima_entrega',
        sortable: false,
        align: 'center'
      },
      { text: 'Pagamento', value: 'pendente_pagamento', align: 'center' },
      { text: 'Status', value: 'ativo', align: 'center' },
      { text: 'Opções', value: 'idassinatura', clickable: false }
    ],
    tableOptions: [
      { text: 'Cancelar', value: 'cancel' },
      { text: 'Detalhes', value: 'detail' }
    ],
    tableOptionsAssInativa: [{ text: 'Detalhes', value: 'detail' }],
    loadingRow: {},
    modulosForm: [ModulesEnum.WEB_COMMERCE, ModulesEnum.COMMERCE_APP]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo', 'getTimeZone']),
    endpoint() {
      return `/api/v4/subscription/datatable/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    onClick(item) {
      this.$router.push(`assinaturas/${item.idassinatura}`)
    },
    onClose() {
      this.showDetails = false
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'cancel':
          return this.onCancel(item)
        case 'detail':
          return this.onClick(item)
      }
    },
    onCancel(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma o cancelamento da assinatura '${item.numero}'?`
      }
      this.confirmSwal(options, () => this.cancel(item))
    },
    cancel(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idassinatura]: true
      }
      this.$store
        .dispatch(ASSINATURA_REMOVE, item.idassinatura)
        .then(() => {
          this.$vueOnToast.pop('success', 'Assinatura cancelada com sucesso')
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idassinatura]: false
          }
        })
        .catch(err => {
          const erro = _.get(
            err,
            'responseJSON.message',
            'Não foi possível cancelar a assinatura'
          )
          this.$vueOnToast.pop('error', erro)
          this.loadingRow = {
            ...this.loadingRow,
            [item.idassinatura]: false
          }
        })
    }
  }
}
</script>
